import axios from 'axios'
import mitt from 'mitt'

export const axios_emitter = mitt()

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            axios_emitter.emit('unauthorized')
        }
        return Promise.reject(error)
    }
)
