import getRole from '../auth/getRole'
import {useUserInfoStore} from '../stores/userInfoStore'

export const hotelInfoCheck = ():string[] => {
    const userRole = getRole()
    console.log("userRole", userRole)
    // return []
    if(!userRole){
        return []
    }
    if(!userRole.includes('酒店')){
        return []
    }
    const hotel = useUserInfoStore().info.hotel
    console.log("hotel", hotel)

    let requiredFields:string[] = []

    if(hotel && hotel.name == ''){
        requiredFields.push('酒店名称')
    }
    if(hotel && hotel.manager_name == ''){
        requiredFields.push('总经理姓名')
    }
    if(hotel && hotel.address == ''){
        requiredFields.push('详细地址')
    }
    if(hotel && hotel.city == ''){
        requiredFields.push('所在城市')
    }
    if(hotel && hotel.ownership == ''){
        requiredFields.push('所属业主')
    }
    if(hotel && hotel.total_area == ''){
        requiredFields.push('总建筑面积')
    }
    if(hotel && hotel.star_rating == ''){
        requiredFields.push('酒店档次')
    }
    if(hotel && hotel.location == ''){
        requiredFields.push('位置')
    }
    if(hotel && hotel.hotel_style == ''){
        requiredFields.push('酒店类型')
    }
    if(hotel && hotel.service_level == ''){
        requiredFields.push('服务范围')
    }
    if(hotel&&(!hotel.brand)){
        requiredFields.push('品牌')
    }
    if(hotel&&(!!hotel.brand)&&(hotel.brand.name == '')){
        requiredFields.push('品牌')
    }
    if(hotel && hotel.year_opened == ''){
        requiredFields.push('开业时间')
    }
    if(hotel && hotel.management_mode == ''){
        requiredFields.push('管理模式')
    }
    if(hotel && hotel.key_count == ''){
        requiredFields.push('客房总数')
    }
    if(hotel && hotel.single_room_count == ''){
        requiredFields.push('单人间数量')
    }
    if(hotel && hotel.standard_room_count == ''){
        requiredFields.push('标准间数量')
    }
    if(hotel && hotel.suite_count == ''){
        requiredFields.push('套房数量')
    }
    if(hotel && hotel.bay_size == ''){
        requiredFields.push('标间面积')
    }
    if(hotel && hotel.restaurant_count == ''){
        requiredFields.push('餐厅总数')
    }
    if(hotel && hotel.restaurant_seat_count == ''){
        requiredFields.push('餐位总数')
    }
    if(hotel && hotel.employee_count == ''){
        requiredFields.push('编制人数')
    }
    if(hotel && hotel.website == ''){
        requiredFields.push('酒店网站')
    }
    if(hotel && hotel.wechat_official_account == ''){
        requiredFields.push('官方微信')
    }
    if(hotel && hotel.contact_name == ''){
        requiredFields.push('联系人')
    }
    if(hotel && hotel.contact_phone == ''){
        requiredFields.push('联系电话')
    }
    
    return requiredFields

}